import React from "react";
import { useNavigate, useParams } from "react-router-dom";
//mui material component
import { Box, Grid, Typography, Paper, Button, Tooltip } from "@mui/material";

//icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SettingsBackupRestoreTwoToneIcon from "@mui/icons-material/SettingsBackupRestoreTwoTone";
// import DotColorful from "../../icons/DotColorful.svg";
// import DotColorless from "../../icons/DotColorless.svg";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

import { useTheme } from "@emotion/react";
import { siteFields } from "./field";
import styled from "styled-components";
import { useAuth } from "../../services/auth";
import { useSite } from "./Provider";
import { useEffect } from "react";
import { useState } from "react";
import ModalBox from "../../ui/ModalBox";
import { convertUTCToLocal } from "../../utilities/functions";
const Grid2 = styled.div`
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 1;
  padding-left: 20px;
  padding-top: 20px;
`;

const InfoSite = ({ reload, setreload }) => {
  let navigate = useNavigate();
  let theme = useTheme();
  const TitleStyle = {
    p: theme.dimensions.padding / 4,
    color: theme.color.PaperlabelColor,
  };
  const TitleStyle2 = {
    p: theme.dimensions.padding / 4,
  };
  const { id } = useParams();
  let { user, role, adminOn } = useAuth();
  const [open, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [LoadingIcon, setLoadingIcon] = useState(false);
  //var to navigate another page

  //close modal function
  const handleCloseClone = () => {
    setOpen(false);
    navigate(-1);
  };
  const {
    state: { selected: SiteInfo, Clone },
    resetCounter,
    fetchOne: fetchSiteInfo,
    fetchTicketPrintingFields,
    siteClone,
  } = useSite();

  const handleClose = () => {
    setOpen(false);
  };

  const getData = (data) => {
    switch (data) {
      case "subCompanyName":
        return SiteInfo.subCompany?.subCompanyName
          ? SiteInfo.subCompany?.subCompany
          : "";
      case "companyName":
        return SiteInfo.company?.companyName
          ? SiteInfo.company?.companyName
          : "";
      case "counterNo":
        return SiteInfo.counter?.counterNo !== null
          ? SiteInfo.counter?.counterNo
          : "";
      case "counterMaxvalue":
        return SiteInfo.counter?.counterMaxvalue
          ? SiteInfo.counter?.counterMaxvalue
          : "";

      case "counterRenwalTime":
        return SiteInfo.counter?.counterRenwalTime
          ? convertUTCToLocal(SiteInfo.counter?.counterRenwalTime)
          : "";
      default:
        break;
    }
  };
  const handleResetCounter = () => {
    setLoadingIcon(true);
    try {
      resetCounter(id);
      // fetchSiteInfo(id);
      //  setFlag(!flag);
      setLoadingIcon(false);
      setreload(!reload);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   fetchSiteInfo(id);
  // }, [id, flag]);
  useEffect(() => {
    if (id) fetchTicketPrintingFields(id);
  }, [id]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     document.getElementById("reset-icon").style.display = "inline";
  //     document.getElementById("reset-icon-loading").style.display = "none";
  //   }, 5000);
  // }, []);

  const handleSuccess = async () => {
    setOpenClone(true);
  };
  const handleClone = async () => {
    try {
      await siteClone(id);
      handleSuccess();
    } catch (error) {
      console.log(error, "error");
    }
  };
  if (!SiteInfo) return;
  return (
    <Paper
      elevation={0}
      // square
      sx={{
        p: theme.dimensions.padding,
        mt: theme.dimensions.mt,
        mb: theme.dimensions.mb,
        background: theme.color.PaperbgColor,
        width: "90%",
      }}
    >
      <Typography variant="Poster" component="h4" gutterBottom>
        Site Information
      </Typography>
      <Grid container>
        {siteFields.map((f, index) => {
          if (f.editOnly) return;
          return (
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              mt={2}
              key={index}
              sx={{ fontSize: "14px" }}
            >
              {f.label !== "Vat" && (
                <>
                  <span style={{ color: theme.color.PaperlabelColor }}>
                    {f.label}:{" "}
                  </span>
                  <span>
                    {SiteInfo[f?.name] && SiteInfo[f?.name]}
                    {getData(f?.viewLabel2)}
                  </span>
                </>
              )}

              {f?.icon && (
                <Tooltip title="Reset Counter">
                  <span
                    // style={{
                    //   marginLeft: 20,
                    //   position: "absolute",
                    //   display: "none",
                    // }}
                    //   id="reset-icon"
                    onClick={() => setOpen(true)}
                  >
                    <SettingsBackupRestoreTwoToneIcon
                      color="action"
                      sx={{
                        fontSize: 20,
                        cursor: "pointer",
                        "&:hover": { color: `${theme.color.black}` },
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {LoadingIcon && (
                <span
                // style={{
                //   marginLeft: 20,
                //   position: "absolute",
                //   display: "inline",
                //   color: theme.color.PaperlabelColor,
                // }}
                //id="reset-icon-loading"
                >
                  Loading...
                </span>
              )}
              {f.name === "includedVat" &&
                (SiteInfo.includedVat ? (
                  <>
                    {SiteInfo?.vat}%{" "}
                    <span style={{ color: "#4AA785" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      Included
                    </span>
                  </>
                ) : (
                  <>
                    {SiteInfo?.vat}%
                    <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        sx={{ fontSize: 10, mr: 0.2 }}
                      />
                      No Included
                    </span>
                  </>
                ))}
              {f.name === "inHouse" &&
                (SiteInfo.inHouse ? (
                  <span style={{ color: "#4AA785" }}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ fontSize: 10, mr: 0.2 }}
                    />
                    Active
                  </span>
                ) : (
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ fontSize: 10, mr: 0.2 }}
                    />
                    Inactive
                  </span>
                ))}
              {f.name === "siteIsActive" &&
                (SiteInfo.isActive ? (
                  <span style={{ color: "#4AA785" }}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ fontSize: 10, mr: 0.2 }}
                    />
                    Active
                  </span>
                ) : (
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                    <FiberManualRecordIcon
                      fontSize="small"
                      sx={{ fontSize: 10, mr: 0.2 }}
                    />
                    Inactive
                  </span>
                ))}
            </Grid>
          );
        })}
      </Grid>
      <Grid container sx={{ pt: 1, fontSize: "14px", mb: 2 }}>
        <Grid
          sx={{ color: theme.color.PaperlabelColor }}
          item
          xs={2}
          sm={4}
          md={4}
          mt={2}
        >
          Fields to be printed:{" "}
          <Typography
            variant="Poster"
            component="span"
            sx={{
              ml: 5,
              color: "#000",
              textDecoration: "underline",
              cursor: "pointer",
              p: 1,
              borderRadius: 10,
              "&:hover": { backgroundColor: theme.color.gray200 },
            }}
            onClick={() => navigate(`TicketPriningFields`)}
          >
            Customize tickets
          </Typography>
        </Grid>
      </Grid>
      {(role === "Admin" || role === "Company") && (
        <Box sx={{ display: "flex", justifyContent: "end" }} mr={2}>
          <Button
            sx={{
              background: "white",
              color: theme.color.blackBackground,
              border: `1px solid ${theme.color.blackBackground}`,
              "&:hover": { background: `white` },
              mr: "10px",
            }}
            onClick={handleClone}
          >
            <FileCopyOutlinedIcon fontSize="medium" />
            Clone
          </Button>
          <Button
            onClick={() => navigate("edit")}
            sx={{
              background: theme.color.blackBackground,
              color: "white",
              "&:hover": { background: `${theme.color.black}` },
            }}
          >
            Edit
          </Button>
        </Box>
      )}
      <ModalBox
        open={open}
        handleClose={handleClose}
        handleOperation={handleResetCounter}
        operation="edit"
        text="Are you sure you want to reset counter?"
      />
      <ModalBox
        open={openClone}
        handleClose={handleCloseClone}
        text={`Clone Site has been completed successfully..`}
      />
    </Paper>
  );
};

export default InfoSite;
