// Providers
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCompany } from "../Companies/Provider";
import { useSite } from "./Provider";
// Yup
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Box } from "@mui/system";
import {
  MenuItem,
  Switch,
  TextField,
  Typography,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Button,
  useTheme,
  Card,
  Alert,
} from "@mui/material";
import styled from "styled-components";
// UI
import ErrorsList from "../../ui/ErrorsList";
// Utilities
import {
  convertTimeToUTC,
  convertUTCToLocal,
  handleResponse,
} from "../../utilities/functions";
// Data
// import { fields, fixedFields, hourlyFields } from "./field";
import {
  siteFields,
  zoneFields,
  zoneFields2,
  fixedFields,
  hourlyFields,
} from "./field";
import countries from "./countries";
import AddTicket from "./TicketPrintingFields/AddTicket";
import CircularProgress from "@mui/material/CircularProgress";

// Style
//css styled
const GridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2px;
  row-gap: 0;
  padding: 20px;
  padding-bottom: 0px;
`;
const GridGroup2 = styled.div`
  display: grid;
  grid-template-columns: 180px 200px 1fr 1fr;
  gap: 2px;
  grid-column-gap: 0px;
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
`;
const GridGroup3 = styled.div`
  display: grid;
  grid-template-columns: 180px 200px 180px 200px;
  gap: 2px;
  grid-column-gap: 0px;
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
`;
const FormGridGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 900px 1fr;
`;
const PrintFieldsGroup = styled.div`
  max-width: 1210px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;
  row-gap: 0px;
  padding: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
`;
const ButtonWrapper = styled.div`
  margin-bottom: 50px;
  margin-top: 30px;
  button {
    float: right;
    margin-left: 20px;
  }
`;
const CountryCode = styled.div`
  .Code {
    width: 300px;
    height: 40px;
  }
`;
const DisabledBackground = styled(Box)({
  width: "100%",
  height: "100%",
  position: "fixed",
  background: "#ccc",
  opacity: 0.5,
  zIndex: 1,
});
const FormSite = ({ site, handleSuccess, setSiteName, formSchema }) => {
  // providers
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const {
    state: { selected: company },
    fetchOne: fetchCompany,
    dispatch,
  } = useCompany();
  const {
    state: { printingDiv1, printingDiv2, printingDiv3, printingDiv4 },
    create,
    edit,
  } = useSite();
  const navigate = useNavigate();
  let theme = useTheme();
  const { id } = useParams();
  // USE STATE
  const [companyName, setCompanyName] = useState(null);
  const [error, setError] = useState(null);
  const currencies = ["USD"];
  const [selectedCountry, setSelectedCountry] = useState("");
  const [LoadingForm, setLoadingForm] = useState(false);
  // WATCH
  const serviceTypeId = watch("service.serviceTypeId");
  const firstHour = watch("service.hours1");
  const firstPeriod = watch("service.period1");
  const price1Trial = watch("service.price1Trial");
  const price1Official = watch("service.price1Official");
  const secondHour = watch("service.hours2");
  const secondPeriod = watch("service.period2");
  // const price2Trial = watch("service.price2Trial");
  // const price2Official = watch("service.price2Official");

  // use effects
  useEffect(() => {
    // if we are adding site => id refers to selected company id
    if (!site) {
      fetchCompany(id);
    } else {
      dispatch({ type: "set_selected", payload: null });
    }
  }, [id, fetchCompany, dispatch, site]);

  useEffect(() => {
    if (site) setSelectedCountry(`${site["countryCode"]}|${site["country"]}`);
  }, [site, setSelectedCountry]);

  useEffect(() => {
    if (company) {
      setCompanyName(company?.companyName);
    }
  }, [company]);

  const [type, setType] = useState(0);
  const [serviceTypeList, setServiceTypeList] = useState([
    { id: 0, value: "" },
  ]);
  // 0 => not selected
  // 1 => Fixed Services
  // 2 => Hourly Services
  const handleChange = (e, f) => {
    // switch between fixed and hourly
    if (f.label === "Services Type") {
      setType(
        e.target.value === "Fixed Services"
          ? "1"
          : e.target.value === "Hourly Services"
          ? "2"
          : "0"
      );
      // Service Type ENUM
      if (e.target.value === "Fixed Services") {
        setServiceTypeList([
          { id: 1, value: "Valet" },
          { id: 3, value: "Self Valet" },
          { id: 5, value: "Re-Valet" },
          // { id: 6, value: "Free-Valet" },
          { id: 6, value: "Loss-Ticket" },
        ]);
      } else if (e.target.value === "Hourly Services") {
        setServiceTypeList([
          { id: 2, value: "Hourly Valet" },
          { id: 4, value: "Self Hourly Valet" },
        ]);
      }
    } else if (f.label === "Country") {
      setSelectedCountry(e.target.value);
    }
  };

  const getHelperText = (f) => {
    if (f?.errorFields) {
      let message = errors;
      for (let i = 0; i < f?.errorFields?.length; i++) {
        if (message && message[f?.errorFields[i]])
          message = message[f.errorFields[i]];
      }
      message = message?.message;
      return message;
    } else return null;
  };

  const getValueMenu = (name) => {
    switch (name) {
      case "servicesType":
        return "value";
      case "country":
        return "country";
      case "serviceTypeId":
        return "id";
      case "currency":
        return;
      default:
        return;
    }
  };

  const getSelectData = (name, f) => {
    switch (name) {
      case "servicesType":
        return f.menuItems;
      case "country":
        return countries;
      case "serviceTypeId":
        return serviceTypeList;
      case "currency":
        if (selectedCountry === "") return currencies;
        var b;
        b = selectedCountry.split("|");
        for (let i = 0; i < countries.length; i++) {
          if (countries[i].country === b[1]) {
            if (countries[i].currency_code === "USD") return currencies;
            return [...currencies, countries[i].currency_code];
          }
        }
    }
  };
  useEffect(() => {
    if (site) {
      setValue("country", site?.country);
      setValue("countryCode", site.countryCode);
    }
  });

  // display field or not depending on selected service type or on Add|Update operation
  const getDisplay = (f) => {
    if (site && f.notEditable) return "none";
    else if (f.fixed && f.hourly) {
      if (type === "1" || type === "2") return "block";
      return "none";
    } else if (f.fixed) {
      if (type === "1") {
        // if (serviceTypeId === 6 && f.label === "Fix Price Trial") return "none";
        // if (serviceTypeId === 6 && f.label === "Fix Price Official")
        //   return "none";
        return "block";
      }
      return "none";
    } else if (f.hourly) {
      if (type === "2") return "block";
      return "none";
    }
    return "block";
  };
  var y;
  useEffect(() => {
    if (!site && getValues("site.country")) {
      y = getValues("site.country").split("|");
      setValue("site.country", y[1]);
      setValue("site.countryCode", y[0]);
    }
  });

  // on submit
  const onSubmit = async (values) => {
    setLoadingForm(true);
    delete values.companyName;
    delete values.serviceType;
    if (site) values.site.companyId = site.company.id;
    else values.site.companyId = id;
    values.site.counter.counterRenwalTime
      ? (values.site.counter.counterRenwalTime = convertTimeToUTC(
          values.site.counter.counterRenwalTime
        ))
      : (values.site.counter.counterRenwalTime =
          site.counter.counterRenwalTime);
    values.printDivisions = [
      {
        divisionId: 1,
        fieldIds: printingDiv1,
      },
      {
        divisionId: 2,
        fieldIds: printingDiv2,
      },
      {
        divisionId: 3,
        fieldIds: printingDiv3,
      },
      {
        divisionId: 4,
        fieldIds: printingDiv4,
      },
    ];
    if (
      values?.service?.printDivision1 === 0 &&
      values?.service?.printDivision2 === 0 &&
      values?.service?.printDivision3 === 0 &&
      values?.service?.printDivision4 === 0
    ) {
      setError({
        title:
          "one division printing number at least must be greater than zero",
      });
      window.scrollTo(0, 0);
      return;
    }
    if (type === "2") {
      if (values.service.period1 % values.service.hours1 !== 0) {
        setError({ title: "the period 1 must be a multiple of hours 1" });
        window.scrollTo(0, 0);
        return;
      }
      if (
        values.service.period2 % values.service.hours2 !== 0 &&
        values.service.period2 !== 0 &&
        values.service.hours2 !== 0
      ) {
        setError({ title: "the period 2 must be a multiple of hours 2" });
        window.scrollTo(0, 0);
        return;
      }
      if (
        (values.service.period1 !== 0 &&
          values.service.hours1 !== 0 &&
          values.service.price1Trial === 0) ||
        (values.service.period2 !== 0 &&
          values.service.hours2 !== 0 &&
          values.service.price2Trial === 0)
      ) {
        setError({ title: "You should specify price trial" });
        window.scrollTo(0, 0);
        return;
      }
      if (
        (values.service.period1 !== 0 &&
          values.service.hours1 !== 0 &&
          values.service.price1Official === 0) ||
        (values.service.period2 !== 0 &&
          values.service.hours2 !== 0 &&
          values.service.price2Official === 0)
      ) {
        setError({ title: "You should specify price official" });
        window.scrollTo(0, 0);
        return;
      }
      // } else if (type === "1" && serviceTypeId !== 6) {
    } else if (type === "1") {
      if (values.service.fixPriceTrial === 0) {
        setError({ title: "You should specify fix price trial" });
        window.scrollTo(0, 0);
        return;
      }
      if (values.service.fixPriceOfficial === 0) {
        setError({ title: "You should specify fix price official" });
        window.scrollTo(0, 0);
        return;
      }
    }
    setError(null);

    try {
      if (site)
        await edit(id, {
          ...values.site,
          country: getValues("site.country").split("|")[1],
          countryCode: getValues("site.country").split("|")[0],
        });
      else {
        await create(values);
        setSiteName(values.site.siteName);
        reset();
      }
      setLoadingForm(false);
      handleSuccess();
    } catch (e) {
      window.scrollTo(0, 0);
      if (e.status === 400) setError(e.errors);
      if (e.status === 404)
        setError({ title: "Pick at least One printing field." });
      if (e.status === 409) setError({ title: "Site Already exists" });
      if (e.status === 500) setError({ title: "Internal Server Error" });
      handleResponse(e.errors.status);
    }
  };

  const fieldsMapping = (arrayOfFields) => {
    {
      return arrayOfFields?.map((fieldsGroup, index) => {
        const GridComponent =
          index === 3 ? GridGroup2 : index === 1 ? GridGroup3 : GridGroup;
        return (
          <GridComponent key={`${index}${index}`}>
            {fieldsGroup.map((f, i) => {
              if (f.viewOnly) return null;
              if (f.alignment) return <Box key={i}></Box>;
              const inputProps = { ...f.inputProps };

              if (i === 0) inputProps.autoFocus = true;
              inputProps.type = f.type;
              return (
                <Box key={i} sx={{ display: getDisplay(f), width: f.width }}>
                  {f.hasValue ? (
                    <Box
                      inputProps={{
                        ...register(f?.registerName),
                      }}
                    >
                      <Typography variant="body2" component="h6" gutterBottom>
                        {f.label}
                      </Typography>
                      <Box
                        sx={{
                          borderBottom: "1px solid gray",
                          width: "290px",
                          borderRadius: "8px",
                          textAlign: "center",
                        }}
                      >
                        {site && site?.company?.companyName}
                        {!site &&
                          (f.label === "Company Name" ? companyName : "")}
                      </Box>
                      {/* <hr style={{ marginRight: "80px" }} /> */}
                    </Box>
                  ) : f.switch ? (
                    <Box>
                      <FormGroup>
                        <FormLabel
                          component="legend"
                          sx={{
                            mt: theme.dimensions.mt,
                          }}
                        >
                          {f.label}
                        </FormLabel>
                        {f.name === "inHouse" && (
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                defaultChecked={site ? site["inHouse"] : false}
                              />
                            }
                            {...register(f?.registerName)}
                            required={f.required}
                            label="Active"
                          />
                        )}
                        {f.name === "serviceIsActive" && (
                          <FormControlLabel
                            control={
                              <Switch color="success" defaultChecked={true} />
                            }
                            {...register(f?.registerName)}
                            required={f.required}
                            label="Active"
                          />
                        )}
                        {f.name === "siteIsActive" && (
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                defaultChecked={site ? site["isActive"] : true}
                              />
                            }
                            {...register(f?.registerName)}
                            required={f.required}
                            label="Active"
                          />
                        )}
                        {f.name == "includedVat" && (
                          <FormControlLabel
                            control={
                              <Switch
                                color="success"
                                defaultChecked={
                                  site ? site["includedVat"] : true
                                }
                              />
                            }
                            {...register(f?.registerName)}
                            required={f.required}
                            label="Active"
                          />
                        )}
                      </FormGroup>
                    </Box>
                  ) : (
                    <TextField
                      label={f.label === "Vat" ? `${f.label} %` : f.label}
                      type={f.type}
                      name={f.name}
                      className={f.class}
                      select={f.select}
                      size="small"
                      error={
                        f.required &&
                        !!errors &&
                        !!f?.errorFields &&
                        !!getHelperText(f)
                      }
                      helperText={getHelperText(f)}
                      fullWidth
                      sx={{
                        width: f.width ? f.width : theme.dimensions.width,
                        mt: theme.dimensions.mt,
                        mb: theme.dimensions.mb,
                        display: f.label === "Country Code" && "none",
                      }}
                      multiline
                      rows={f.longText && 6}
                      // if we are adding a site and this field is disabled and has a value:
                      {...(company && f.hasValue
                        ? { value: company[f.value] }
                        : {
                            defaultValue: !company
                              ? f?.label === "Company Name"
                                ? companyName
                                : ""
                              : f.label !== "Company Name"
                              ? company[f?.name]
                              : company?.company?.companyName,
                          })}
                      // if we are editing a site
                      {...(site && f.hasValue
                        ? {
                            value: site?.company?.companyName,
                          }
                        : {})}
                      // Select Functionality
                      {...(f.select && site && { defaultValue: site[f?.name] })}
                      {...(f.select && { required: f.required })}
                      {...(f.select && {
                        onChange: (e) => handleChange(e, f),
                      })}
                      // disable hours2 and period2 and hours3 until hours1 and period1 is filled
                      disabled={
                        f.dependentdisable2
                          ? firstHour !== "0" &&
                            firstPeriod !== "0" &&
                            price1Trial !== "0" &&
                            price1Official !== "0"
                            ? false
                            : true
                          : f.dependentdisable3
                          ? secondHour !== "0" &&
                            secondPeriod !== "0" &&
                            firstHour !== "0" &&
                            firstPeriod !== "0"
                            ? false
                            : true
                          : f.disabled
                      }
                      placeholder={f.placeholder}
                      required={f.required}
                      inputProps={{
                        ...register(f?.registerName),
                        min: f.min,
                        max: f.max,
                      }}
                      defaultValue={
                        site
                          ? f?.name.includes("counter")
                            ? site?.counter && f.name === "counterRenwalTime"
                              ? convertUTCToLocal(
                                  site?.counter.counterRenwalTime
                                )
                              : site?.counter[f?.name]
                            : f.label != "Country"
                            ? site[f?.name]
                            : `${site.countryCode}|${site.country}`
                          : f.defaultValue
                      }
                      //   onChange={(e) => console.log(e.target.value)}
                    >
                      {f.select &&
                        getSelectData(f.name, f)?.map((item, index) => {
                          const valueMenu = getValueMenu(f.name);
                          return (
                            <MenuItem
                              key={`${item[valueMenu]}${index}`}
                              value={
                                valueMenu
                                  ? valueMenu === "country"
                                    ? `${item["dial_code"]}|${item[valueMenu]}`
                                    : item[valueMenu]
                                  : item
                              }
                            >
                              {f.label === "Service Type"
                                ? item.value
                                : valueMenu
                                ? valueMenu === "country"
                                  ? item["dial_code"] + " " + item[valueMenu]
                                  : item[valueMenu]
                                : item}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  )}
                </Box>
              );
            })}
          </GridComponent>
        );
      });
    }
  };
  const fieldsRendering = (arrayOfFields, title) => {
    return (
      <Card
        sx={{
          p: theme.dimensions.padding,
          mt: 3,
          mb: 3,
        }}
      >
        <Typography variant="Poster" component="h4" gutterBottom>
          {title}
        </Typography>
        {fieldsMapping(arrayOfFields)}
      </Card>
    );
  };
  return (
    <>
      {LoadingForm ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" size="2rem" />
        </Box>
      ) : null}
      {/* make gray box on center */}
      <Box>
        {/* Body */}

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {error && (
            <Alert severity="error">
              <ErrorsList error={error} />
            </Alert>
          )}
          {fieldsRendering([siteFields], "Site Details")}
          {!site &&
            fieldsRendering(
              [zoneFields, zoneFields2, fixedFields, hourlyFields],
              "Zone Details"
            )}
          {/* Print Field */}
          {!site && (
            <Card
              sx={{
                p: theme.dimensions.padding,
                mt: theme.dimensions.mt,
                mb: theme.dimensions.mb,
              }}
            >
              <AddTicket ticket={false} />
            </Card>
          )}
          {/* End  Print Field  */}
          {/* End Body */}
          {/* Buttons */}
          <ButtonWrapper>
            <Button
              variant="contained"
              type="submit"
              sx={{
                backgroundColor: theme.color.blackBackground,
                ml: theme.dimensions.ml,
                "&:hover": { background: `${theme.color.black}` },
              }}
            >
              {site ? "Save" : "Add Site"}
            </Button>
            <Button
              variant="contained"
              onClick={() => navigate(-1)}
              sx={{
                backgroundColor: theme.color.grayBackground,
                color: theme.color.dark,
                "&:hover": { background: `${theme.color.gray50}` },
              }}
            >
              Discard
            </Button>
          </ButtonWrapper>
        </form>
      </Box>
    </>
  );
};

export default React.memo(FormSite);
